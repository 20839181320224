export default [
  { key: "no", label: "ลำดับที่", sortable: true , thStyle:{color:"white",width: "5% !important"}},
  {
    key: "hwModelName",
    label: "Model",
    thStyle:{color:"white"}
  },  
  { key: "hwModelStatus", label: "สถานะ", sortable: true , thStyle:{color:"white",width: "8% !important"}},  
  {
    key: "action",
    label: "จัดการ",
    sortable: false,
    thStyle: { width: "12% !important", textAlign: "center", color:"white" },
  },
];
