<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col md="4" class="my-1">
            <h2><b>Model ({{ $route.query.typeName }} / {{ $route.query.brandName }})</b></h2>
            <small>
              <router-link to="/setting/hardware/type"><b>จัดการ Hardware</b></router-link>  > 
              <span class="text-primary" style="cursor: pointer;" @click="$router.go(-1)"><b>{{ $route.query.typeName }}</b></span> >              
              <span ><b>{{ $route.query.brandName }}</b> </span>  
            
            </small>
          </b-col>
          <b-col md="8" class="my-1">
            <b-form inline class="d-flex justify-content-end">
              <b-input-group class="input-group-merge" style="width: 40%">
                <b-form-input
                  id="basic-password1"
                  type="text"
                  placeholder="Search..."
                  v-model="filter"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-input-group-append>
              </b-input-group>

              <b-button
                v-if="hardwarePermission=='create' || hardwarePermission=='edit'"
                variant="primary"
                class="ml-1 shadow"
                v-b-modal.add-hw-model-modal
                ><feather-icon icon="PlusCircleIcon" size="14" /> เพิ่ม Model
              </b-button>
              <b-button
                variant="outline-dark"
                class="ml-2 shadow"
                @click="$router.go(-1)"
                ><feather-icon icon="ArrowLeftIcon" size="14" /> ย้อนกลับ
              </b-button>
            </b-form>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-table
              style="font-size: 12px"
              class="shadow"
              small
              striped
              bordered
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="visibleFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :busy="isLoading"
            >
            <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>

            <template #cell(no)="row">
                <center>{{ row.index + 1 }}</center>
              </template>

              <template #cell(hwModelStatus)="row">
                <center>
                  <span
                    v-if="row.item.hwModelStatus == 1"
                    class="text-primary"
                  >
                  เปิดใช้งาน</span
                  >
                  <span v-else class="text-danger"> ปิด</span>
                </center>
              </template>

              <template #cell(action)="row" v-if="hardwarePermission=='create' || hardwarePermission=='edit'">
                <center>
                  <b-button variant="outline-secondary" size="sm" pill v-b-modal.add-hw-model-modal @click="onSelectItemForEdit(row.item)">
                    <feather-icon icon="EditIcon" class="cursor-pointer" />
                  </b-button>

                  <b-button variant="danger" class="ml-1" size="sm" pill @click="deleteHwModel(row.item.id)">
                    <feather-icon icon="TrashIcon" class="cursor-pointer" />
                  </b-button>
                </center>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="2">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>

          <b-col cols="10" class="d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              variant="secondary"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <AddHardwareModel :modelData="modelData" @emitSaveHwModel="onSave"/>
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BForm,
  BSpinner
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import Field from "./field";
import Data from "./data";

import AddHardwareModel from "./component/modal/addHardwareModel.vue";

//SERVICE
import HardwareService from "@/services/setting/hardware"

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BForm,
    BSpinner,
    AddHardwareModel,
  },
  data() {
    return {
      perPage: 1000,
      pageOptions: [1000, 2000, 5000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: Field,
      items: Data,   
      modelData:{        
        hwModelName: "",
        hwModelStatus: 1,
        mode: "stock",
        brandId: 0
      },
      isLoading:false  
    };
  },


  computed :{
    hardwarePermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("ST001")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission      
    },  
    visibleFields() {
        return this.fields.filter(field => {
          if(this.$store.getters["user/getPermissionPage"]("ST001") !== "edit"){
            return field.key !== "action"
          }
          return field
        })
    }  
  },
  mounted() {
    this.getHardwareModel()
    
  },
  methods: {
    async getHardwareModel(){
      this.isLoading = true
      const paramBrandId = this.$route.params.brandId
      const result = await HardwareService.GetHwModelList(paramBrandId, "stock", "all=true")
      this.items = result.data
      this.totalRows = this.items.length;
      this.isLoading = false
    },
    async insertHardwareModel(){
      const paramBrandId = this.$route.params.brandId
      const result = await HardwareService.InsertHwModel({...this.modelData, brandId:paramBrandId})
      if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `รุ่นอุปกรณ์`,
              icon: "CheckIcon",
              variant: "success",
              text: `เพิ่มรุ่นอุปกรณ์เรียบร้อย`,
            },
          });
          this.getHardwareModel()
          this.modelData =  {       
            hwModelName: "",
            hwModelStatus: 0,
            mode: "stock",
            brandId: 0
          }  
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `รุ่นอุปกรณ์`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการเพิ่มรุ่นอุปกรณ์`,
            },
          });
        }
      
    },
    async updateHardwareModel(){
      const result = await HardwareService.UpdateHwModel(this.modelData,this.modelData.id)
      if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `รุ่นอุปกรณ์`,
              icon: "CheckIcon",
              variant: "success",
              text: `แก้ไขยี่ห้ออุปกรณ์เรียบร้อย`,
            },
          });
          this.getHardwareModel()         
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `รุ่นอุปกรณ์`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการแก้ไขยี่ห้ออุปกรณ์`,
            },
          });
        }
    },
    async deleteHwModel(hwModelId){
      const isConfirm = confirm("คุณต้องการลบรายการนี้หรือไม่ ?")
      if(!isConfirm) return false;

      const result = await HardwareService.DeleteHwModel(hwModelId)
      if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `รุ่นอุปกรณ์`,
              icon: "CheckIcon",
              variant: "success",
              text: `ลบรุ่นอุปกรณ์เรียบร้อย`,
            },
          });
          this.getHardwareModel()         
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `รุ่นอุปกรณ์`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการลบรุ่นอุปกรณ์`,
            },
          });
        }
    },

    async onSave(){
      if(this.modelData.id){
        await this.updateHardwareModel()
        this.modelData =  {       
            hwModelName: "",
            hwModelStatus: 0,
            mode: "stock",
            brandId: 0
          }  
      }else{
        await this.insertHardwareModel()
      }
    },
    onSelectItemForEdit(item){
      this.modelData = item

    },    
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.table-hover tbody tr:hover {
  background-color: aqua !important;
}
</style>
