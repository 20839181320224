export default [
    {
      no: 1,
      hardwareName: "UGREEN", 
      status:"available"     
    },      
    {
      no: 2,
      hardwareName: "JP1081B", 
      status:"available"     
    },     
    {
      no: 3,
      hardwareName: "Hub 24 Port", 
      status:"notAvailable"     
    },       
  ]